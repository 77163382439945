import React from "react";
import { graphql } from "gatsby";
import BlockContent from "@sanity/block-content-to-react";

import Layout from "../components/layout";

import "../styles/suppliers.scss";

const SuppliersPage = ({ data }) => {
  const pageData = data.sanitySuppliers;

  return (
    <Layout pageTitle="Suppliers">
      <div className="text">
        <BlockContent blocks={pageData._rawText} />
      </div>
    </Layout>
  );
};

export const query = graphql`
  {
    sanitySuppliers(_id: { eq: "single-suppliers" }) {
      _rawText
    }
  }
`;

export default SuppliersPage;
